import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { useFetchComponentData } from '../hooks';
import { TextComponent as TextComponentType } from '../../../configs/component-types';
import { actionPostComponentEdit, actionPostKeyEdit } from '../actions';
import { GenericComponent } from '../Component';
import ReactMarkdown from 'react-markdown';
import { getContextRow, processTokens } from '../helpers';
import { getComponentById, getComponentSource } from '../../../reducers/selectors/post';
import { useMemo } from 'react';
import { useResolvedStyles } from '../hooks';
import './style.css';
import remarkGfm from 'remark-gfm';
import CSS from 'csstype';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

export interface TextComponentProps {
  componentId: string;
  componentIndex: number;
  dataRow?: Dictionary;
  region?: number;
  layout?: string;
  textAlign?: string;
  cardIndex?: number; // card index
  isEmbed?: boolean;
  editPostKeyActionCreator?: typeof actionPostKeyEdit;
  editComponentActionCreator?: typeof actionPostComponentEdit;
}

const defaultProps = {
  editPostKeyActionCreator: actionPostKeyEdit,
  editComponentActionCreator: actionPostComponentEdit,
};

const TextComponent: React.FC<TextComponentProps> = (props: TextComponentProps) => {
  const dispatch = useDispatch();

  // memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  // get styles
  const styles = useResolvedStyles();

  const {
    componentId,
    componentIndex,
    dataRow,
    cardIndex,
    editComponentActionCreator,
    editPostKeyActionCreator,
    isEmbed,
  } = props;


  const component = useSelector((state) => {
    /* @ts-ignore */
    const text = selectComponentById(state, { componentId });

    if (!text) {
      return undefined;
    }
    return text as unknown as TextComponentType;
  });

  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));

  const activeComponentIndex: number = useSelector(
    (state) => (state as Dictionary).config.activeComponentIndex
  );
  const [isLoading, data] = useFetchComponentData(componentId, 50000, cardIndex);

  const drawerIsOpen: boolean = useSelector((state) => (state as Dictionary).config.drawerOpen);
  const [text, setText] = useState<string>('');
  const post = useSelector((store: Dictionary) => store.post);

  const Paragraph = (props: Dictionary) => {
    return (
      <p
        style={{
          lineHeight: `${component?.lineHeight}` || 1,
          fontFamily: component?.fontFamily ? component?.fontFamily : styles?.bodyFontFamily,
          fontWeight: styles?.bodyFontWeight || 400,
          color: component?.color || styles?.textColor,
        }}
      >
        {props.children}
      </p>
    );
  };

  const Heading = (props: Dictionary) => {
    const { children, node } = props;
    const post = useSelector((store: Dictionary) => store.post);
    if (node.tagName === 'h1') {
      return (
        <h1
          style={{
            fontSize: '34px',
            color: styles?.headingColor,
            fontFamily: styles?.headingFontFamily,
            fontWeight: styles?.headingFontWeight || 700,
            lineHeight: `${component?.lineHeight}` || 1,
          }}
        >
          {children}
        </h1>
      );
    }
    if (node.tagName === 'h2') {
      return (
        <h2
          style={{
            fontSize: '30px',
            color: styles?.headingColor,
            fontFamily: styles?.headingFontFamily,
            fontWeight: styles?.headingFontWeight || 700,
            lineHeight: `${component?.lineHeight}` || 1,
          }}
        >
          {children}
        </h2>
      );
    }
    if (node.tagName === 'h3') {
      return (
        <h3
          style={{
            fontSize: '24px',
            color: styles?.headingColor,
            fontFamily: styles?.headingFontFamily,
            fontWeight: styles?.headingFontWeight || 700,
            lineHeight: `${component?.lineHeight}` || 1,
          }}
        >
          {children}
        </h3>
      );
    }
    if (node.tagName === 'h4') {
      return (
        <h4
          style={{
            fontSize: '20px',
            color: styles?.headingColor,
            fontFamily: styles?.headingFontFamily,
            fontWeight: styles?.headingFontWeight || 700,
            lineHeight: `${component?.lineHeight}` || 1,
          }}
        >
          {children}
        </h4>
      );
    }
    if (node.tagName === 'h5') {
      return (
        <h5
          style={{
            fontSize: '18px',
            color: styles?.headingColor,
            fontFamily: styles?.headingFontFamily,
            fontWeight: styles?.headingFontWeight || 700,
            lineHeight: `${component?.lineHeight}` || 1,
          }}
        >
          {children}
        </h5>
      );
    }
    return (
      <h6
        style={{
          fontSize: '16px',
          color: styles?.headingColor,
          fontFamily: styles?.headingFontFamily,
          fontWeight: styles?.headingFontWeight || 700,
          lineHeight: `${component?.lineHeight}` || 1,
        }}
      >
        {children?.[0]}
      </h6>
    );
  };

  useEffect(() => {
    if (component?.text) {
      setText(component.text);
    }
  }, [component?.text]);

  useEffect(() => {
    if (
      activeComponentIndex === componentIndex &&
      drawerIsOpen === true &&
      text !== '' &&
      text !== component?.text
    ) {
      handleSave();
    }
    // eslint-disable-next-line
  }, [activeComponentIndex, componentIndex, drawerIsOpen, text]);

  const { filteredData } = getContextRow(dataRow, data, component, true);

  const getParsedText = () => {
    if (filteredData[0]) {
      return processTokens(text, component, filteredData[0], source);
    } else {
      return text
    }
  };

  const genericComponentProps = {
    componentIndex,
    componentId,
    drawerTitle: 'Text',
    isEmbed: isEmbed,
  };

  const handleSave = () => {
    if (editComponentActionCreator && editPostKeyActionCreator) {
      dispatch(
        editComponentActionCreator({
          index: componentIndex,
          field: 'text',
          value: text,
        })
      );
    }
  };

  return (
    <GenericComponent {...genericComponentProps}>
      <div className="post--component-content">
        <div className="post--component-text-content">
          <div
            style={
              {
                color: component?.color,
                fontSize: component?.fontSize || styles?.textFontSize,
                fontFamily: component?.fontFamily || 'PT Serif',
                textAlign: component?.textAlign || 'left',
              } as CSS.Properties
            }
          >
            <span className={isLoading ? 'tokens-loading' : 'tokens-loaded'}>
              <ReactMarkdown
                skipHtml={true}
                remarkPlugins={[remarkGfm]}
                components={{
                  h1: Heading,
                  h2: Heading,
                  h3: Heading,
                  h4: Heading,
                  h5: Heading,
                  h6: Heading,
                  p: Paragraph,
                }}
              >
                {getParsedText()}
              </ReactMarkdown>
            </span>
          </div>
        </div>
      </div>
    </GenericComponent>
  );
};

TextComponent.defaultProps = defaultProps;

export { TextComponent };
