import React from 'react';
import { Pagination } from 'antd';
import { CARD_DEFAULT_PAGINATION_SIZE } from '../../../../configs/constants';

/** interface for component props */
export interface PaginatedComponentProps {
  setCurrentPage: (page: number) => void;
  setCurrentPageSize: (pageSize: number) => void;
  onPageChange: (page: number) => void; //  called when page number is changed
  onPageSizeChange: (size: number) => void; // called when page size is changed
  total?: number; // total number of data items
  pageSize?: number; // number of data items per page
  simple?: boolean;
  pageSizeOptions?: string[]; // size changer options
  currentPage?: number;
}

const ComponentPagination: React.FC<PaginatedComponentProps> = (props: PaginatedComponentProps) => {
  const { total, pageSize, simple, pageSizeOptions, onPageChange, onPageSizeChange, setCurrentPage, setCurrentPageSize, currentPage } = props;

  const handleOnChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (page) {
      onPageChange(page);
    }

    if (pageSize) {
      onPageSizeChange(pageSize);
      setCurrentPageSize(pageSize);
    }
  };

  return (
    <Pagination
      showSizeChanger={pageSizeOptions ? !!pageSizeOptions.length : false}
      hideOnSinglePage={pageSizeOptions ? !pageSizeOptions.length : true}
      pageSizeOptions={pageSizeOptions}
      onChange={handleOnChange}
      defaultPageSize={CARD_DEFAULT_PAGINATION_SIZE}
      simple={simple}
      pageSize={pageSize}
      total={total}
      current={currentPage}
    />
  );
};

export { ComponentPagination };
