import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from "react-oidc-context";
import { actionUserGet } from '../../actions';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../configs/env';
import { message } from 'antd';
import { ERROR_GENERIC } from '../../../../configs/constants';

const CheckToken = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.profile?.sub) {
      document.body.classList.add('user-auth');
      const service = new AkukoAPIService(IDENTITY_API, `user/profile`);
      service.read(user?.profile?.sub).then((res) => {
        dispatch(actionUserGet(res));
        if (res?.email) {
          document.body.classList.add('user-auth');
        }
      })
      .catch((error) => {
        if (error?.message) {
          // Do not display error related to user resource
          if (!error?.message?.includes(`Resource 'user/${user?.profile?.email}' not found`)) {
            message.error(error.message);
          }
        } else {
          message.error(ERROR_GENERIC);
        }
      });
    }
  }, [user]);

  return null;
}

export default CheckToken;
