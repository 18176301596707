import { Dictionary } from '@onaio/utils';
import { format, isValid } from 'date-fns';
import { SignJWT } from 'jose';
import moment from 'moment';

import { GenericPostComponent, SourceQueryObject } from '../../configs/component-types';
import { CUBE_SECRET, AKUKO_QUERY_JWT_HEADER_ALGO, AKUKO_QUERY_JWT_TOKEN_EXPIRATION_TIME } from '../../configs/env';
import {
  getComponentDimensions,
  getComponentMeasures,
  getFilters,
  getLayerDimensions,
  getLayerFilters,
  getLayerMeasures,
  getTimeDimension,
} from "./helpers";


/**
 * Build API query object
 *
 * @param {GenericPostComponent} component post component
 * @param {string} connector Database connector type
 * @param {string} uuid Source uuid
 * @param {number} componentIndex component index
 * @param {number} limit max number of rows
 * @returns {SourceQueryObject | null} sources API query object or null if build failed
 */
export const buildQueryObj = (
  component: GenericPostComponent,
  uuid: string,
  limit: number,
  refreshKey: number | string | undefined,
  cubeName: string,
  offset?: number 
): SourceQueryObject | null => {
  const dimensions = getComponentDimensions(component);
  const measures = getComponentMeasures(component);
  const filters = getFilters(component.filters || [], component.cube);
  const timeDimension = getTimeDimension(component);
  if (!dimensions.length && !measures.length) {
    // We return null since empty dimensions and measures has the API returning 500
    return null;
  }

  // don't allow custom limit on charts

  if (component.type === "number") {
    limit = 50000;
  }
  const tDimensions = timeDimension.map((row) => row.dimension);

  const tableOffset =  offset ? (offset - 1) * limit : 0;

  const queryObj: SourceQueryObject = {
    uuid,
    refreshKey: refreshKey,
    cubeName: cubeName,
    query: {
      dimensions: dimensions.filter(
        (dimension) => !tDimensions.includes(dimension)
      ),
      measures: measures || [],
      filters: filters || [],
      timeDimensions: timeDimension || [],
      limit,
      offset: component.offset ? component.offset :  tableOffset,
      total: true
    },
  };

  // don't allow custom sort on charts
  if (component.sortField) {
    queryObj.query.order = {
      [`${component.cube}.${component.sortField}`]:
        component.sortOrder || "asc",
    };
  }

  return queryObj;
};

/**
 * Build API query object
 *
 * @param {GenericPostComponent} layer map layer
 * @param {string} connector Database connector type
 * @param {string} uuid Source uuid
 * @param {number} componentIndex component index
 * @param {number} limit max number of rows
 * @returns {SourceQueryObject | null} sources API query object or null if build failed
 */
export const buildLayerQueryObj = (
  source: Dictionary,
  layer: Dictionary,
  uuid: string,
  limit: number,
  refreshKey: number | string | undefined,
  cubeName: string
): SourceQueryObject | null => {
  const dimensions = getLayerDimensions(layer, source);
  const measures = getLayerMeasures(layer, source);
  const layerFilter = layer.filters;
  const filters = getLayerFilters(layerFilter || [], source);

  if (!dimensions.length && !measures.length) {
    // We return null since empty dimensions and measures has the API returning 500
    return null;
  }

  const queryObj: SourceQueryObject = {
    uuid,
    refreshKey: refreshKey,
    cubeName: cubeName,
    query: {
      dimensions: dimensions || [],
      measures: measures || [],
      filters: filters || [],
      limit,
    },
  };

  return queryObj;
};

export const sortPageSizeOptions = (options: string[]): string[] => {
  const parsedOptions = options
    .map((option) => Number(option))
    .sort((a, b) => {
      return a - b;
    });

  return parsedOptions.map((option) => option.toString());
};

export const getLowestPageOption = (options: string[]): string => {
  return sortPageSizeOptions(options)[0];
};

export const genericDateFormatter = (
  dateFormat: string,
  value?: Date
): string => {
  if (dateFormat && value) {
    const dateValue = new Date(value);
    if (isValid(dateValue)) {
      if (isValid(moment.utc(value, true).valueOf())) {
        return format(moment.utc(value, true).valueOf(), dateFormat);
      }
    }
    return String(value);
  }
  return "-";
};

export const generateJWTToken = async (payload: Dictionary) => {
  const secret = new TextEncoder().encode(CUBE_SECRET);
  const jwt = await new SignJWT(payload)
    .setProtectedHeader({ alg: AKUKO_QUERY_JWT_HEADER_ALGO })
    .setIssuedAt()
    .setExpirationTime(AKUKO_QUERY_JWT_TOKEN_EXPIRATION_TIME)
    .sign(secret);

  return jwt;
};
