import React, { useState, useEffect } from 'react';
import { Popover, message } from 'antd';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';

const SpaceUsers = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (props.accountId && props.spaceId) {
      const service = new AkukoAPIService(
        IDENTITY_API,
        `user/space/${props.accountId}/${props.spaceId}`
      );
      service
        .list()
        .then((users) => {
          props.actionAccountUsersGet(users);
          setUsers(users);
        })
        .catch((e) => {
          if(e?.message === undefined) {
            message.error(ERROR_GENERIC);
          }
          if (!e?.message?.includes('Forbidden')) {
            message.error(e?.message);
          }
        });
    }
  }, [props.accountId, props.spaceId]);

  return (
    <div className='space-users'>
      {users.map(
        (user, index) =>
          index < 11 && (
            <Popover key={index} content={user.email}>
              {user.image ? (
                <div
                  className='space-user'
                  style={{
                    backgroundImage: `url(https://assets.akuko.io/${user.image})`,
                  }}
                ></div>
              ) : (
                <div className='space-user'></div>
              )}
            </Popover>
          )
      )}
      {users.length > 10 && (
        <div className='more-users'>+ {users.length - 10} more</div>
      )}
    </div>
  );
};

export default SpaceUsers;
