import React, { useState, useEffect } from 'react';
import './style.css';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { EditPostButton } from '../EditPostButton';
import { SignInButton } from '../SignInButton';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { Dropdown, Modal, message } from 'antd';
import type { MenuProps } from 'antd';
import { actionUserLogout } from '../actions';
import { ExportPost } from '../../Post/PostComponents/components/ExportPost';
import { AkukoAPIService } from '../../../services/serviceClass';
import { POSTS_API, SOURCES_API, IDENTITY_API } from '../../../configs/env';
import { EmbedSettings } from '../../Post/Embed/EmbedSettings';
import { userHasWriteAccess } from '../../Profile/components/ProfileMenu/helpers';
import { ERROR_GENERIC } from '../../../configs/constants';
import { UserDropdown } from '../UserDropdown';
import mixpanel from 'mixpanel-browser';
import { PostMenu } from '../PostMenu';
import './style.css';

const GlobalNav = () => {

  const user = useSelector((store: Dictionary) => store.user);
  const post = useSelector((store: Dictionary) => store.post);
  const space = useSelector((store: Dictionary) => store.space);
  const history = useHistory<Dictionary>();
  const location = useLocation<Dictionary>();
  const params = useParams<Dictionary>();
  const dispatch = useDispatch();
  const [exportPost, setExportPost] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const pathname: string = location.pathname;

  const shouldShow = () => {
    if (location.pathname.includes('/edit')) {
      return false;
    }
    if (location.pathname.includes('/post/') && !post.uuid) {
      return false;
    }
    if (post.space_id && !user.authenticated) {
      return false;
    }
    if (post.public) {
      return false;
    }
    return true;
  }



  useEffect(() => {
    if (shouldShow()) {
      document.body.classList.add('has-global-nav')
      return () => {
        document.body.classList.remove('has-global-nav')
      }
    }
  }, [user.authenticated, post.uuid])



  return (
    shouldShow() ?

      <div className="global-nav">
        <PostMenu />
        {!user.authenticated &&
          <p className="global-nav-prompt">Akuko is a platform for telling stories with data</p>
        }
        <EditPostButton />
        <UserDropdown />
        <Modal
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
        >
          Modal content
        </Modal>
      </div>
      : null
  )

}

export { GlobalNav }; 