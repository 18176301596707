import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  getComponentById,
  getComponentData,
  getComponentSource,
  getComponentSourceQueryHistoryObject,
  getComponentFilterData,
  dataSelector,
  sourceQueryHistorySelector,
} from '../../reducers/selectors/post';
import { actionComponentSourceQuery, actionPostComponentErrorAdd } from './actions';
import { isEqual } from 'lodash';
import { buildQueryObj, sortPageSizeOptions, getLowestPageOption, generateJWTToken } from './utils';
import { AkukoAPIService } from '../../services/serviceClass';
import { QUERY_API, AKUKO_APP_RETRY_INTERVAL, AKUKO_APP_RETRY_COUNT, AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME } from '../../configs/env';
import { buildTimeDimension, getComponentDimensions, getFilters } from './helpers';
import { message } from 'antd';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;
const makeGetComponentData = () => getComponentData;
const makeGetComponentSourceQueryHistoryObj = () => getComponentSourceQueryHistoryObject;
const makeGetComponentFilterData = () => getComponentFilterData;

/**
 * Custom hook to fetch data with retries
 */

export const fetchWithRetry: any = async (
  fetchFunction: any,
  retryCount = AKUKO_APP_RETRY_COUNT,
  interval = AKUKO_APP_RETRY_INTERVAL
) => {
  for (let attempt = 0; attempt <= retryCount; attempt++) {
    try {
      const result = await fetchFunction();
      if (result?.error !== 'Continue wait') {
        return;
      }

      if (attempt === retryCount) {
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, interval));
    } catch (err) {
      return;
    }
  }
};



export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * Custom hook to fetch component data
 *
 * @param {string} componentId id of the component
 * @returns {[boolean, Dictionary[]]}  an array that contains loading status, data
 */
export const useFetchComponentData = (
  componentId: string,
  limit = 50000,
  cardIndex?: number,
  currentPage?: number,
  currentPageSize?: number,
  setCurrentPage?: (x: number) => void
): [boolean, Dictionary[], number] => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);
  const selectComponentData = useMemo(makeGetComponentData, []);
  const selectComponentSourceQueryHistoryObj = useMemo(makeGetComponentSourceQueryHistoryObj, []);
  /* @ts-ignore */
  const component = useSelector((state) => selectComponentById(state, { componentId }));



  const dataLimit = component?.limit || limit;

  const sourceQueryHistoryObj = useSelector((state) => {
    return component?.id
      /* @ts-ignore */
      ? selectComponentSourceQueryHistoryObj(state, { componentId: component.id })
      : undefined;
  });
  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));

  const structuredFilters = getFilters(component?.filters, source?.cube)?.map((filter: any) => filter.member);

  // Track previous filters to detect changes
  const previousComponentFilters = usePrevious(JSON.stringify(structuredFilters));


  const queryObj = useMemo(() => {

    const filtersChanged = JSON.stringify(structuredFilters) !== previousComponentFilters;

    if (filtersChanged && setCurrentPage && currentPage !== 1) {
      setCurrentPage(1);
    }

    let latestPageSize = currentPageSize;
    let latestPage = currentPage;
    if (!component?.pagination) {
      latestPageSize = component?.limit;
      latestPage = undefined;
    }
    return component && source?.uuid
      ? buildQueryObj(component, source.uuid, latestPageSize || dataLimit, source?.refresh_key, source?.cube, latestPage)
      : null;
  }, [component, source?.uuid, dataLimit, source?.refresh_key, source?.cube, currentPage, currentPageSize]);

  /* @ts-ignore */
  const data = useSelector((state) => selectComponentData(state, { componentId }));

  // use custom hook for comparing the queryObj in the dependency array since its deeply nested

  const fetchComponentData = useCallback(async () => {
    setLoading(true);
    const token = await generateJWTToken({
      sourceId: source?.uuid,
      cubeName: source?.cube,
      refreshKey: source?.refresh_key,
    });
    const headers = {
      [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
      'Content-Type': 'application/json'
    };
    const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
    try {
      if (queryObj && component) {
        const res: any = await service.create({
          query: queryObj?.query,
        });

        if (res.error === 'Continue wait') {
          return res;
        }
        const queryResult = res.data as Dictionary[];

        setTotalRecords(res?.total);

        /* @ts-ignore */
        dispatch(actionComponentSourceQuery(queryResult, component?.id, queryObj.query));
        // remove component id from active query index
        const index = window.akukoQueryIndex.indexOf(component?.id);
        if (index > -1) {
          window.akukoQueryIndex.splice(index, 1);
        }
        setLoading(false);
        return res; // Return data
      }
    } catch (error) {
      dispatch(
        actionPostComponentErrorAdd({
          id: component?.id,
          name: component?.name,
          type: component?.type,
          errors: error,
        })
      );
      setLoading(false);
      // remove component id from active query index
      if (component) {
        const index = window.akukoQueryIndex.indexOf(component?.id);
        if (index > -1) {
          window.akukoQueryIndex.splice(index, 1);
        }
      }
      return error;
    }
  }, [dispatch, queryObj, component?.id]);

  useEffect(() => {
    // if this query originates from a card, only run query on first card
    if (!cardIndex || cardIndex < 1) {
      /**
       * We compare the component's query history object saved in the store and the current object.
       * If they are equal, then we have the right data in the store. No need to refetch
       */
      if (queryObj && !isEqual(sourceQueryHistoryObj, queryObj.query)) {
        // keep an array of component ids which
        // are currently running queries
        // and only launch a new query if there is
        // no existing query running -- not sure we should store this on the window
        // but works for now
        if (component?.id !== undefined) {
          if (
            !window.akukoQueryIndex.includes(component?.id) ||
            (sourceQueryHistoryObj !== undefined &&
              queryObj &&
              !isEqual(sourceQueryHistoryObj, queryObj.query))
          ) {
            // add component id to active query index
            if (component?.type !== 'map') {
              window.akukoQueryIndex.push(component?.id);
            }
            setLoading(true);
            (async () => await fetchWithRetry(fetchComponentData as any))();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryObj, component?.id, sourceQueryHistoryObj, cardIndex, dispatch]);

  return [isLoading, data || [], totalRecords];
};

export interface PaginationHook {
  enablePagination: boolean;
  pageSize: number;
  pageSizeOptions: string[];
  paginatedData: Dictionary[];
  simple: boolean | undefined;
  onPageSizeChange: (size: number) => void;
  onPageChange: (page: number) => void;
}

/**
 * Custom hook to handle pagination
 *
 * @param {string} componentId index of the post component
 * @param {Dictionary[]} data array of items to be paginated
 * @param {number} defaultPageSize page size to use
 * @returns {PaginationHook} values from pagination results and handlers
 */
export const usePagination = (
  componentId: string,
  data: Dictionary[] = [],
  defaultPageSize = 10
): PaginationHook => {
  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [pageSizeOptions, setPageSizeOptions] = useState<string[]>([]);

  const component = useSelector((state) => {
    /* @ts-ignore */
    return selectComponentById(state, { componentId });
  });


  const enablePagination = component?.pagination === true;
  const configuredPageSize = Number(component?.defaultPageSize);
  const sizeOptions = component?.pageSizeOptions;
  const showSizeChanger = component?.showSizeChanger;
  const simple = component?.simplePager || false;

  useEffect(() => {
    if (configuredPageSize) {
      setPageSize(configuredPageSize);
    } else {
      if (sizeOptions && sizeOptions.length && showSizeChanger) {
        // use the lowest size option
        setPageSize(Number(getLowestPageOption(sizeOptions)));
      } else {
        // Reset to the default in a case where the config was removed
        setPageSize(defaultPageSize);
      }
    }
  }, [configuredPageSize, sizeOptions, showSizeChanger, defaultPageSize]);

  useEffect(() => {
    if (sizeOptions && sizeOptions && showSizeChanger) {
      setPageSizeOptions(sortPageSizeOptions(sizeOptions));
    } else {
      // Reset to default in case config was removed or is empty
      setPageSizeOptions([]);
    }
  }, [sizeOptions, showSizeChanger]);

  const paginatedData = useMemo(() => {
    if (enablePagination && pageSize) {
      if (pageSize >= data.length) return data;

      return data.slice((page - 1) * pageSize, page * pageSize);
    }
    return data;
  }, [enablePagination, data, pageSize, page]);


  return {
    enablePagination,
    pageSize,
    pageSizeOptions,
    simple,
    paginatedData,
    onPageSizeChange: (size: number) => {
      setPageSize(size);
    },
    onPageChange: (page: number) => {
      setPage(page);
    },
  };
};

/**
 * Custom hook to fetch component's single filter data
 *
 * @param {string} componentId id of the component
 * @param {number} filterIndex index of the filter
 * @returns {[boolean, Dictionary[]]}  an array that contains loading status, data
 */
export const useFetchSingleFilterData = (
  componentId: string,
  filterIndex: number,
  limit = 20000
): [boolean, Dictionary[]] => {
  const dispatch = useDispatch();

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);
  const selectComponentSourceQueryHistoryObj = useMemo(makeGetComponentSourceQueryHistoryObj, []);
  const selectComponentFilterData = useMemo(makeGetComponentFilterData, []);

  const [isLoading, setLoading] = useState<boolean>(false);

  /* @ts-ignore */
  const component = useSelector((state) => selectComponentById(state, { componentId }));
  const property = component?.filters ? component.filters[filterIndex][1] : '';
  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));
  const fullComponentId = `${component?.id}-filter-${filterIndex}`;

  const sourceQueryHistoryObj = useSelector((state) => {
    return component?.id
      /* @ts-ignore */
      ? selectComponentSourceQueryHistoryObj(state, {
        componentId: fullComponentId,
      })
      : undefined;
  });
  const data = useSelector((state) => {
    /* @ts-ignore */
    return selectComponentFilterData(state, { componentId, filterIndex });
  });

  useEffect(() => {
    if (source?.cube && source?.uuid && property) {
      let filters = [];
      /**
       * Only apply previous filters on the current filter
       * filter index is the guiding value an index/pointer
       */
      if (component?.filters?.length && filterIndex !== 0 && component?.cascade) {
        const excludeFinalFilters = [...component?.filters];
        excludeFinalFilters.pop();
        excludeFinalFilters.splice(filterIndex);
        filters =
          component?.filters?.length > 1 ? getFilters(excludeFinalFilters, source.cube) : [];
      }
      const filterConfigs = component?.filters?.[filterIndex];
      let timeDimension;

      if (component?.filters?.[filterIndex]?.granularity) {
        timeDimension = buildTimeDimension(
          component?.cube,
          filterConfigs?.[1] || filterConfigs?.[2],
          filterConfigs?.granularity
        );
      }
      const tDimensions = timeDimension?.map((row) => row.dimension);
      const queryObj = {
        uuid: source.uuid,
        refreshKey: source?.refresh_key,
        cubeName: source?.cube,
        query: {
          dimensions: [`${source.cube}.${property}`].filter(
            (dimension) => !tDimensions?.includes(dimension)
          ),
          measures: [],
          limit,
          filters: filters,
          timeDimensions: timeDimension || [],
        },
      };

      /**
       * We compare the component's query history object saved in the store and the current object.
       * If they are equal, then we have the right data in the store. No need to refetch
       */
      if (!isEqual(sourceQueryHistoryObj, queryObj.query)) {
        setLoading(true);
        generateJWTToken({
          sourceId: source?.uuid,
          cubeName: source?.cube,
          refreshKey: source?.refresh_key,
        }).then(res => {
          const token = res;
          const headers = {
            [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
            'Content-Type': 'application/json'
          };
          const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
          service.create({
            query: queryObj?.query
          })
            .then((data) => {
              const res = data as Dictionary;
              const queryResult = res.data as Dictionary[];
              /* @ts-ignore */
              dispatch(actionComponentSourceQuery(queryResult, fullComponentId, queryObj.query));
            })
            .catch((error) => {
              dispatch(
                actionPostComponentErrorAdd({
                  id: component?.id,
                  name: component?.name,
                  type: component?.type,
                  errors: error,
                })
              );
            })
            .finally(() => {
              setLoading(false);
            });
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fullComponentId,
    property,
    source?.cube,
    source?.uuid,
    limit,
    sourceQueryHistoryObj,
    dispatch,
    component?.filters,
    component?.cascade,
  ]);

  return [isLoading, data || []];
};

/**
 * Custom hook to fetch component's all filter data
 *
 * @param {string} componentId id of the component
 * @param {number} filterIndex index of the filter
 * @returns {[boolean, Dictionary[]]}  an array that contains loading status, data
 */
export const useFetchFiltersData = (componentId: string, limit = 20000): [boolean, Dictionary] => {
  const dispatch = useDispatch();

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<Dictionary>({});
  /* @ts-ignore */
  const component = useSelector((state) => selectComponentById(state, { componentId }));
  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));
  const postData = useSelector(dataSelector);
  const allQueryHistory = useSelector(sourceQueryHistorySelector);

  useDeepCompareEffect(() => {
    if (component?.filters) {
      const data: Dictionary = {};

      component.filters.forEach((_, filterIndex) => {
        const id = `${component.id}-filter-${filterIndex}`;
        data[id] = postData[id];
      });

      // Only update if the data has changed
      if (!isEqual(data, filterData)) {
        setFilterData(data);
      }
    }
  }, [postData, component?.filters, component?.id]);

  useDeepCompareEffect(() => {
    if (component?.id && source?.uuid && component?.filters && source?.cube) {
      component.filters.forEach((filter, filterIndex) => {
        const property = filter[1];
        if (property) {
          const fullComponentId = `${component.id}-filter-${filterIndex}`;
          const queryObj = {
            uuid: source.uuid,
            refreshKey: source?.refresh_key,
            cubeName: source?.cube,
            query: {
              dimensions: [`${source.cube}.${property}`],
              measures: [],
              limit,
            },
          };

          if (!isEqual(allQueryHistory[fullComponentId], queryObj.query)) {
            generateJWTToken({
              sourceId: source?.uuid,
              cubeName: source?.cube,
              refreshKey: source?.refresh_key,
            }).then(res => {
              const token = res;
              const headers = {
                [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
                'Content-Type': 'application/json'
              };
              const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
              service.create({
                query: queryObj?.query,
              })
                .then((data) => {
                  const res = data as Dictionary;
                  const queryResult = res.data as Dictionary[];
                  /* @ts-ignore */
                  dispatch(actionComponentSourceQuery(queryResult, fullComponentId, queryObj.query));
                })
                .catch((error) => {
                  dispatch(
                    actionPostComponentErrorAdd({
                      id: component?.id,
                      name: component?.name,
                      type: component?.type,
                      errors: error,
                    })
                  );
                })
                .finally(() => {
                  setLoading(false);
                });
            });
          }
        }
      });
    }
  }, [
    component?.id,
    component?.filters,
    component?.cube,
    source?.cube,
    source?.uuid,
    allQueryHistory,
    limit,
  ]);

  return [isLoading, filterData];
};

interface UseDataSelectionParams {
  componentIndex: number;
  item: Dictionary;
  parents: string[];
  itemIndex?: number;
  childIndex?: number;
}

interface SelectOption {
  label: string;
  value: string | number;
}

interface UseDataSelectionReturn {
  value: string;
  selectOptions: SelectOption[];
  handleSelectChange: (newValue: string, optionData: any) => void;
}

/**
 * A hook for managing data selection in settings UI.
 */
export const useDataSelection = ({
  componentIndex,
  item,
  parents,
  itemIndex,
  childIndex,
}: UseDataSelectionParams): UseDataSelectionReturn => {

  const post = useSelector((state: any) => state.post); // Assuming `state.post` type is any. Replace `any` with your actual state type.
  const component = post.components[componentIndex];
  const [value, setValue] = useState<string>('');
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  const measures = useSelector((state: any) => post.sources[component?.source]?.measures || []);
  const dimensions = useSelector((state: any) => post.sources[component?.source]?.dimensions || []);

  useEffect(() => {
    // Construct select options here
  }, [measures, dimensions, item]);

  const handleSelectChange = (newValue: string, optionData: any): void => {
    setValue(newValue);
    // Implement the change logic here
  };

  return { value, selectOptions, handleSelectChange };
};

/**
 * Custom hook to get post / space styles
 *
 * @returns {Dictionary} style object
 */
export const useResolvedStyles = (): Dictionary => {
  const postConfig = useSelector((state: Dictionary) => state.post.config);
  const spaceConfig = useSelector((state: Dictionary) => state.space.config);

  // Implement the resolution logic here and return the resolved styles
  const resolvedStyle = useMemo(
    () => ({
      cellFontSize: postConfig?.cellFontSize || spaceConfig?.cellFontSize || 12,
      primaryColor: postConfig?.primaryColor || spaceConfig?.primaryColor || '#111',
      itemColor: postConfig?.itemColor || spaceConfig?.itemColor || '#111111',
      headingColor: postConfig?.headingColor || spaceConfig?.headingColor || '#000000',
      headingFontWeight: postConfig?.headingFontWeight || spaceConfig?.headingFontWeight || 700,
      headingFontFamily:
        postConfig?.headingFontFamily || spaceConfig?.headingFontFamily || 'Poppins',
      bodyFontFamily: postConfig?.bodyFontFamily || spaceConfig?.bodyFontFamily || 'PT Serif',
      bodyFontWeight: postConfig?.bodyFontWeight || spaceConfig?.bodyFontWeight || 400,
      textColor: postConfig?.textColor || spaceConfig?.textColor || '#000000',
      textFontSize: postConfig?.textFontSize || spaceConfig?.textFontSize || 16,
      headerColor: postConfig?.headerColor || spaceConfig?.headerColor || '#000000',
      colorText: postConfig?.colorText || spaceConfig?.colorText || '#000000',
      colorBgContainer: postConfig?.colorBgContainer || spaceConfig?.colorBgContainer || '#ffffff',
      tableFontFamily: postConfig?.tableFontFamily || spaceConfig?.tableFontFamily || 'Poppins',
      headerBg: postConfig?.headerBg || spaceConfig?.headerBg || '#eeeeee',
      headerSortActiveBg: postConfig?.headerSortActiveBg || spaceConfig?.headerSortActiveBg || '#dddddd',
      headerSortHoverBg: postConfig?.headerSortHoverBg || spaceConfig?.headerSortActiveBg || '#dddddd',
      headerSplitColor: postConfig?.headerSplitColor || spaceConfig?.headerSplitColor || '#ffffff',
      cellPaddingBlock: postConfig?.cellPaddingBlock || spaceConfig?.cellPaddingBlock || 10,
      tableBorderColor: postConfig?.tableBorderColor || spaceConfig?.tableBorderColor || '#eeeeee',
      borderColor: postConfig?.borderColor || spaceConfig?.borderColor || '#eeeeee',
      itemBg: postConfig?.itemBg || spaceConfig?.itemBg || '#ffffff',
      itemHoverBg: postConfig?.itemHoverBg || spaceConfig?.itemHoverBg || '#ededed',
      itemHoverColor: postConfig?.itemHoverColor || spaceConfig?.itemHoverColor || '#444',
      itemSelectedBg: postConfig?.itemSelectedBg || spaceConfig?.itemSelectedBg || '#dddddd',
      itemSelectedColor: postConfig?.itemSelectedBg || spaceConfig?.itemSelectedBg || '#dddddd',
      defaultBorderColor: postConfig?.itemSelectedColor || spaceConfig?.itemSelectedColor || '#111111',
      borderRadius: postConfig?.borderRadius || spaceConfig?.borderRadius || 8,
      backgroundColor: postConfig?.backgroundColor || spaceConfig?.backgroundColor,
      fontSize: postConfig?.fontSize || spaceConfig?.fontSize || 12,
      fontFamily: postConfig?.fontFamily || spaceConfig?.fontFamily || 'Poppins'
    }),
    [postConfig, spaceConfig]
  );

  return resolvedStyle;
};


